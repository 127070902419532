/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body.dark .widget-content-area {
  border: 1px solid #0e1726;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  padding: 0;
  background-color: #0e1726;
}
body.dark .no-content:before, body.dark .no-content:after {
  display: none !important;
}
body.dark .dataTables_wrapper {
  padding: 0;
}
body.dark .dt--top-section {
  margin: 20px 21px 20px 21px;
}
body.dark .dt--bottom-section {
  padding: 15px;
}
body.dark .table-form {
  display: flex;
  margin: 17px 21px 25px 21px;
  justify-content: space-between;
}
body.dark .table-form .form-group {
  margin-bottom: 0;
}
body.dark .table-form .form-group label {
  color: #888ea8;
  font-size: 14px;
  align-self: center;
}
body.dark .table-form .form-group input {
  padding: 7px 18px 7px 14px;
  height: auto;
  font-size: 12px;
}
body.dark table.dt-table-hover tbody tr:hover {
  background: rgba(3, 3, 5, 0.122);
}
body.dark table.dataTable thead .sorting:before, body.dark table.dataTable thead .sorting_asc:before, body.dark table.dataTable thead .sorting_desc:before, body.dark table.dataTable thead .sorting_asc_disabled:before, body.dark table.dataTable thead .sorting_desc_disabled:before {
  color: #d3d3d3;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bfc9d4' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  width: 14px;
  height: 14px;
  content: "";
  right: 0.3rem;
  top: 0.5rem;
}
body.dark table.dataTable thead .sorting:after, body.dark table.dataTable thead .sorting_asc:after, body.dark table.dataTable thead .sorting_asc_disabled:after, body.dark table.dataTable thead .sorting_desc:after, body.dark table.dataTable thead .sorting_desc_disabled:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bfc9d4' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  width: 14px;
  height: 14px;
  content: "";
  right: 0.3rem;
  top: 1.3rem;
}
body.dark table.dataTable thead .sorting_asc:before, body.dark table.dataTable thead .sorting_desc:after {
  color: #0e1726;
}
body.dark table.dataTable > thead > tr, body.dark table.dataTable > tfoot > tr {
  border: none;
}
body.dark table.dataTable > tbody > tr > td .t-dot {
  background-color: #000;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
}
body.dark .page-item .page-link:hover {
  background: #191e3a;
  color: #bfc9d4;
}
body.dark table.dataTable {
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-collapse: collapse !important;
  background: transparent;
}
body.dark .table > tbody tr {
  border-radius: 4px;
  border-bottom: 1px solid #191e3a;
}
body.dark .table.table-hover tbody tr {
  background-color: transparent;
}
body.dark .table > thead > tr > th {
  text-transform: initial;
  font-weight: 600;
  border-top: none;
  background: #1b2e4b;
  border-right: none;
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 10px 21px 10px 21px;
  color: #bfc9d4;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  white-space: nowrap;
}
body.dark .table > tbody > tr > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
  display: none !important;
  inset: auto 0 auto auto !important;
  right: 0;
  left: auto;
}
body.dark .table > tbody > tr > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show {
  display: block !important;
}
body.dark .table > tbody > tr:last-child > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu, body.dark .table > tbody > tr:nth-last-child(2) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu, body.dark .table > tbody > tr:nth-last-child(3) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
  right: 0;
  left: auto;
}
body.dark .table > tbody > tr:last-child > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show, body.dark .table > tbody > tr:nth-last-child(2) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show, body.dark .table > tbody > tr:nth-last-child(3) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show {
  top: -108px !important;
}
body.dark .table > tbody > tr > td {
  font-size: 14px;
  border: none;
  padding: 0;
  padding: 10px 21px 10px 21px;
  color: #888ea8;
  letter-spacing: 1px;
  white-space: nowrap;
}
body.dark .table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}
body.dark .dataTable.table-striped tbody tr:nth-of-type(odd) td {
  --bs-table-accent-bg:transparent;
  background-color: rgba(3, 3, 5, 0.122) !important;
  color: #fff;
}
body.dark .dataTable.table-striped.table > thead > tr > th {
  background: transparent;
  border-top: 1px solid #0e1726 !important;
  border-bottom: 1px solid #0e1726 !important;
}
body.dark .table > tfoot > tr > th {
  border: none;
  padding: 10px 21px 10px 21px;
  color: #bfc9d4;
}
body.dark .table-hover:not(.table-dark) tbody tr:hover {
  background-color: transparent !important;
}
body.dark .table-hover.non-hover:not(.table-dark) tbody tr:hover {
  -webkit-transform: none;
  transform: none;
}
body.dark div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: normal;
  color: #d78283;
  font-weight: 600;
  border: 1px solid #1b2e4b;
  display: inline-block;
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 13px;
}
body.dark div.dataTables_wrapper div.dataTables_filter label {
  position: relative;
  margin-bottom: 0;
}
body.dark div.dataTables_wrapper div.dataTables_filter svg {
  position: absolute;
  top: 11px;
  right: 9px;
  width: 18px;
  height: 18px;
  color: #bfc9d4;
}
body.dark .dataTables_wrapper .form-control {
  background: #0e1726;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid #1b2e4b;
  font-size: 14px;
  padding: 7px 16px;
  height: calc(1.3em + 1.3rem + 2px);
  transition: none;
}
body.dark div.dataTables_wrapper button:hover {
  -webkit-transform: none;
  transform: none;
}
body.dark div.dataTables_wrapper .table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
}
body.dark .table > thead > tr > th.dt-no-sorting:before, body.dark .table > thead > tr > th.dt-no-sorting:after {
  display: none;
}
body.dark .dataTable.table-hover > tbody > tr:hover td:first-child, body.dark .dataTable.table-hover > tbody > tr:hover td:last-child {
  border-radius: 0;
}
body.dark .dataTables_wrapper .form-control::-webkit-input-placeholder, body.dark .dataTables_wrapper .form-control::-ms-input-placeholder, body.dark .dataTables_wrapper .form-control::-moz-placeholder {
  color: #bfc9d4;
  font-size: 12px;
}
body.dark div.dataTables_wrapper div.dataTables_filter input {
  width: 150px;
}
body.dark div.dataTables_wrapper div.dataTables_length label {
  font-size: 14px;
  margin-bottom: 0;
}
body.dark .dataTables_wrapper .dataTables_length select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #0e1726 url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='none' stroke='%23d3d3d3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e") 54px 12px no-repeat;
  padding: 7px 18px 7px 14px;
}
body.dark div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
  display: inline-block;
}
body.dark .page-link {
  margin-right: 5px;
  border-radius: 8px;
  background: rgba(0, 23, 55, 0.08);
  border: none;
  color: #888ea8;
  height: 33px;
  width: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
body.dark .page-link:focus {
  box-shadow: none;
}
body.dark div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 3px 0;
  flex-wrap: wrap;
}
body.dark .page-item.disabled .page-link {
  background: transparent;
}
body.dark .page-item.disabled .page-link svg {
  color: #888ea8;
}
body.dark .page-item:first-child .page-link {
  border-radius: 8px;
  padding: 0;
  height: 33px;
  width: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
body.dark .page-item:first-child .page-link svg {
  width: 17px;
}
body.dark .page-item:last-child .page-link {
  border-radius: 8px;
  padding: 0;
  height: 33px;
  width: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
body.dark .page-item.next:not(.disabled) .page-link, body.dark .page-item.previous:not(.disabled) .page-link {
  background: #1b2e4b;
}
body.dark .page-item.next:not(.disabled) .page-link svg, body.dark .page-item.previous:not(.disabled) .page-link svg {
  color: #fff;
}
body.dark .page-item:last-child .page-link svg {
  width: 17px;
}
body.dark .page-item.active .page-link {
  background-color: #F66062;
  color: #fff;
}
body.dark #alter_pagination_next a, body.dark #alter_pagination_previous a {
  padding: 0;
}
body.dark #alter_pagination_next a svg, body.dark #alter_pagination_previous a svg {
  width: 17px;
}
body.dark .table-cancel {
  color: #888ea8;
  margin-right: 6px;
  vertical-align: middle;
  fill: rgba(0, 23, 55, 0.08);
  cursor: pointer;
}
body.dark .table-hover:not(.table-dark) tbody tr:hover .table-cancel {
  color: #e7515a;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body.dark .dataTables_wrapper .dataTables_length select.form-control {
    background: transparent;
    padding: 8px 10px 8px 14px;
  }
}
