/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
html {
  min-height: 100%;
  direction: ltr;
}

body.dark {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #060818;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.0312rem;
  font-family: "Nunito", sans-serif;
}

body.dark {
  /*Page title*/
  /* 
  =====================
      Navigation Bar
  =====================
  */
  /* User Profile Dropdown*/
  /* 
  ===============
      Sidebar
  ===============
  */
  /* 
  ===============
      Sidebar
  ===============
  */
  /*  
      ======================
          Footer-wrapper
      ======================
  */
}
body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark h5, body.dark h6 {
  color: #e0e6ed;
}
body.dark :focus {
  outline: none;
}
body.dark p {
  margin-top: 0;
  margin-bottom: 0.625rem;
  color: #888ea8;
}
body.dark hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #515365;
}
body.dark strong {
  font-weight: 600;
}
body.dark code {
  color: #e7515a;
}
body.dark .page-header {
  border: 0;
  margin: 0;
}
body.dark .page-header:before {
  display: table;
  content: "";
  line-height: 0;
}
body.dark .page-header:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}
body.dark .page-title h3 {
  margin: 0;
  margin-bottom: 0;
  font-size: 20px;
  color: #e0e6ed;
  font-weight: 600;
}
body.dark .page-title span {
  display: block;
  font-size: 11px;
  color: #555555;
  font-weight: normal;
}
body.dark .main-container {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 0 0 16px;
}
body.dark #container.fixed-header {
  margin-top: 56px;
}
body.dark #content {
  /* width: 50%; */
  flex-grow: 8;
  margin-top: 107px;
  margin-bottom: 0;
  margin-left: 212px;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
body.dark .main-container-fluid > .main-content > .container {
  float: left;
  width: 100%;
}
body.dark #content > .wrapper {
  -webkit-transition: margin ease-in-out 0.1s;
  -moz-transition: margin ease-in-out 0.1s;
  -o-transition: margin ease-in-out 0.1s;
  transition: margin ease-in-out 0.1s;
  position: relative;
}
body.dark .widget {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}
body.dark .layout-top-spacing {
  margin-top: 20px;
}
body.dark .layout-spacing {
  padding-bottom: 24px;
}
body.dark .layout-px-spacing {
  padding: 0 24px !important;
  min-height: calc(100vh - 112px) !important;
}
body.dark .widget.box .widget-header {
  background: #0e1726;
  padding: 0px 8px 0px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: none;
  border-bottom: none;
}
body.dark .row [class*=col-] .widget .widget-header h4 {
  color: #bfc9d4;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  padding: 16px 15px;
}
body.dark .seperator-header {
  background: transparent;
  box-shadow: none;
  margin-bottom: 40px;
  border-radius: 0;
}
body.dark .seperator-header h4 {
  margin-bottom: 0;
  line-height: 1.4;
  padding: 5px 8px;
  font-size: 15px;
  border-radius: 4px;
  letter-spacing: 1px;
  display: inline-block;
  background: rgba(0, 150, 136, 0.26);
  color: #009688;
  font-weight: 500;
}
body.dark .widget .widget-header {
  border-bottom: 0px solid #f1f2f3;
}
body.dark .widget .widget-header:before {
  display: table;
  content: "";
  line-height: 0;
}
body.dark .widget .widget-header:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}
body.dark .widget-content-area {
  padding: 20px;
  position: relative;
  background-color: #0e1726;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  border-top: none;
}
body.dark .content-area {
  max-width: 58.333333%;
  margin-left: 80px;
}
body.dark .header-container {
  background: #060818;
  z-index: 1032;
  position: fixed;
  top: 0;
  padding: 4px 20px 4px 16px;
  width: 100%;
}
body.dark .header-container.container-xxl {
  left: 255px;
}
body.dark .header-container .theme-brand {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding: 0.9px 0 0.9px 12px;
  justify-content: space-between;
}
body.dark .header-container .theme-brand .theme-logo a img {
  width: 124px;
  height: auto;
  margin-right: 30px;
}
body.dark .header-container .theme-text {
  margin-right: 32px;
}
body.dark .header-container .theme-text a {
  font-size: 24px;
  color: #e0e6ed;
  line-height: 2.75rem;
  padding: 0 0.8rem;
  text-transform: initial;
  position: unset;
  font-weight: 700;
}
body.dark .navbar {
  padding: 0;
}
body.dark .navbar-expand-sm .navbar-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
  list-style: none;
}
body.dark .navbar.navbar-expand-sm .navbar-item .nav-item {
  align-self: center;
}
body.dark .navbar.navbar-expand-sm .navbar-item .nav-item.language-dropdown {
  margin-left: 20px;
}
body.dark .navbar.navbar-expand-sm .navbar-item .nav-item.theme-toggle-item {
  margin-left: 20px;
}
body.dark .navbar.navbar-expand-sm .navbar-item .nav-item.notification-dropdown {
  margin-left: 20px;
}
body.dark .navbar.navbar-expand-sm .navbar-item .nav-item.user-profile-dropdown {
  margin: 0 0 0 16px;
}
body.dark .navbar-expand-sm .navbar-item .nav-link {
  color: #e0e6ed;
  position: unset;
}
body.dark .navbar .toggle-sidebar, body.dark .navbar .sidebarCollapse {
  display: inline-block;
  position: relative;
  color: #0e1726;
}
body.dark .navbar .navbar-item .nav-item.theme-toggle-item .nav-link {
  padding: 4.24px 0;
}
body.dark .navbar .navbar-item .nav-item.theme-toggle-item .nav-link:after {
  display: none;
}
body.dark .navbar .light-mode, body.dark:not(.dark) .navbar .light-mode {
  display: inline-block;
  color: #e2a03f;
  fill: #e2a03f;
}
body.dark .navbar .dark-mode, body.dark:not(.dark) .navbar .dark-mode {
  display: inline-block;
  color: #bfc9d4;
  fill: #bfc9d4;
}
body.dark .navbar .light-mode {
  display: none;
}
body.dark .navbar .dropdown-menu {
  border-radius: 8px;
  border-color: #e0e6ed;
}
body.dark .navbar .navbar-item .nav-item.dropdown.show a.nav-link span {
  color: #F66062 !important;
}
body.dark .navbar .navbar-item .nav-item.dropdown.show a.nav-link span.badge {
  background-color: #2196f3 !important;
  color: #fff !important;
}
body.dark .navbar .navbar-item .nav-item .dropdown-item.active, body.dark .navbar .navbar-item .nav-item .dropdown-item:active {
  background-color: transparent;
  color: #16181b;
}
body.dark .navbar .navbar-item .nav-item.dropdown .nav-link:hover span {
  color: #F66062 !important;
}
body.dark .navbar .navbar-item .nav-item.dropdown .dropdown-menu {
  border-radius: 0;
  border: none;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
  box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
  background: #1b2e4b;
  left: auto;
  top: 23px !important;
}
body.dark .navbar .navbar-item .nav-item.dropdown .dropdown-menu.show {
  top: 38px !important;
}
body.dark .navbar .navbar-item .nav-item.dropdown .dropdown-menu .dropdown-item {
  border-radius: 0;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown a.dropdown-toggle:after {
  display: none;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown a.dropdown-toggle img {
  width: 25px;
  height: 25px;
  border-radius: 8px;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu {
  min-width: 7rem;
  right: -8px !important;
  left: auto !important;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu .dropdown-item:hover {
  background: transparent !important;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu .dropdown-item.active, body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu .dropdown-item:active {
  background: transparent;
  color: #16181b;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu a img {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  border-radius: 8px;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu a span {
  color: #bfc9d4;
  font-weight: 500;
}
body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu .dropdown-item:hover span {
  color: #fff !important;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .nav-link:after {
  display: none;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .nav-link svg {
  color: #fff;
  stroke-width: 1.5;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .nav-link span.badge {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
  color: #fff !important;
  background: #00ab55;
  top: -5px;
  right: 2px;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu {
  min-width: 15rem;
  right: -8px !important;
  left: auto;
  padding: 0;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .notification-scroll {
  height: 375px;
  position: relative;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .drodpown-title {
  padding: 14px 16px;
  border-bottom: 1px solid rgba(81, 83, 101, 0.28);
  border-top: 1px solid rgba(81, 83, 101, 0.28);
  margin-bottom: 10px;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .drodpown-title.message {
  border-top: none;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .drodpown-title h6 {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 200;
  color: #fff;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .dropdown-item {
  padding: 0.625rem 1rem;
  cursor: pointer;
  border-radius: 0;
  background: transparent;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .media {
  margin: 0;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu img {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 3px solid #e0e6ed;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu svg {
  width: 23px;
  height: 23px;
  font-weight: 600;
  color: #e2a03f;
  margin-right: 9px;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .media.file-upload svg {
  color: #e7515a;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .media.server-log svg {
  color: #009688;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .media-body {
  display: flex;
  justify-content: space-between;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .data-info {
  display: inline-block;
  white-space: normal;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .data-info h6 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  color: #e0e6ed;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .dropdown-item:hover .data-info h6 {
  color: #d78283;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .data-info p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  color: #888ea8;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .icon-status {
  white-space: normal;
  display: none;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .dropdown-item:hover .icon-status {
  display: block;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .icon-status svg {
  margin: 0;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .icon-status svg.feather-x {
  color: #bfc9d4;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .icon-status svg.feather-x:hover {
  color: #e7515a;
}
body.dark .navbar .navbar-item .nav-item.notification-dropdown .dropdown-menu .icon-status svg.feather-check {
  color: #fff;
  background: #00ab55;
  border-radius: 50%;
  padding: 3px;
  width: 22px;
  height: 22px;
}
body.dark .navbar form.form-inline input.search-form-control::-webkit-input-placeholder, body.dark .navbar form.form-inline input.search-form-control::-ms-input-placeholder, body.dark .navbar form.form-inline input.search-form-control::-moz-placeholder {
  color: #888ea8;
  letter-spacing: 1px;
}
body.dark .navbar .form-inline.search {
  display: inline-block;
}
body.dark .navbar .form-inline.search .search-form-control {
  font-size: 14px;
  background-color: #0e1726;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #888ea8;
  letter-spacing: 1px;
  padding: 0px 4px 0px 40px;
  height: 36px;
  font-weight: 600;
  width: 370px;
  border: 1px solid rgba(81, 83, 101, 0.28);
}
body.dark .navbar .search-animated {
  position: relative;
}
body.dark .navbar .search-animated .badge {
  position: absolute;
  right: 6px;
  top: 6.5px;
  font-size: 11px;
  letter-spacing: 1px;
  transform: none;
  background-color: #bfc9d4;
  color: #000;
}
body.dark .navbar .search-animated svg {
  font-weight: 600;
  margin: 0 9.6px;
  cursor: pointer;
  color: #888ea8;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  pointer-events: none;
}
body.dark .navbar .search-animated svg.feather-x {
  display: none;
  width: 18px;
  height: 18px;
}
body.dark .search-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparent !important;
  z-index: 814 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
body.dark .search-overlay.show {
  display: block;
  opacity: 0.1;
}
body.dark .navbar .navbar-item .nav-item.dropdown.user-profile-dropdown .nav-link:after {
  display: none;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu {
  padding: 0 10px 10px 10px !important;
  z-index: 9999;
  max-width: 13rem;
  min-width: 11rem;
  right: 4px !important;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu:after {
  border-bottom-color: #b1b2be !important;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .user-profile-section {
  padding: 16px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: -1px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(59, 63, 92, 0.25);
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .user-profile-section .media {
  margin: 0;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .user-profile-section .media img {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 3px solid rgba(0, 0, 0, 0.16);
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .user-profile-section .media .emoji {
  font-size: 19px;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .user-profile-section .media .media-body {
  align-self: center;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .user-profile-section .media .media-body h5 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
  color: #e0e6ed;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .user-profile-section .media .media-body p {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  color: #d78283;
}
body.dark .navbar .navbar-item .nav-item.dropdown.user-profile-dropdown .nav-link:after {
  display: none;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .nav-link svg {
  color: #bfc9d4;
  stroke-width: 1.5;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu.show {
  top: 45px !important;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item {
  padding: 0;
  background: transparent;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item a {
  display: block;
  color: #e0e6ed;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 14px;
  border-radius: 8px;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item:hover a {
  color: #d78283;
  background: #0e1726;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item.active, body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item:active {
  background-color: transparent;
}
body.dark .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu .dropdown-item svg {
  width: 18px;
  margin-right: 7px;
  height: 18px;
}
body.dark .secondary-nav {
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 1031;
  left: 0;
  right: 0;
  display: flex;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  background: rgb(26, 28, 45);
  min-height: 52px;
}
body.dark .secondary-nav .breadcrumbs-container {
  display: flex;
  width: 100%;
}
body.dark .secondary-nav .breadcrumbs-container .navbar {
  border-radius: 0;
  padding: 9px 0;
  justify-content: flex-start;
  width: 100%;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .sidebarCollapse {
  position: relative;
  padding: 0 25px 0 31px;
  margin-left: 0;
  padding-left: 31px;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .sidebarCollapse svg {
  width: 20px;
  height: 20px;
  color: #e0e6ed;
  vertical-align: text-top;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-action-dropdown .custom-dropdown-icon {
  padding-right: 24px;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-action-dropdown .custom-dropdown-icon a.dropdown-toggle {
  position: relative;
  padding: 9px 35px 9px 10px;
  border: 1px solid #3b3f5c;
  border-radius: 8px;
  transform: none;
  font-size: 13px;
  line-height: 17px;
  background-color: rgb(26, 28, 45);
  letter-spacing: normal;
  min-width: 115px;
  text-align: inherit;
  color: #e0e6ed;
  box-shadow: none;
  max-height: 35px;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-action-dropdown .custom-dropdown-icon a.dropdown-toggle svg.custom-dropdown-arrow {
  position: absolute;
  right: 15px;
  top: 11px;
  color: #888ea8;
  width: 13px;
  height: 13px;
  margin: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-action-dropdown .custom-dropdown-icon .dropdown-menu {
  top: 3px !important;
  padding: 8px 0;
  border: none;
  min-width: 155px;
  background-color: #191e3a;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-action-dropdown .custom-dropdown-icon .dropdown-menu a {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 500;
  color: #e0e6ed;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-action-dropdown .custom-dropdown-icon .dropdown-menu a svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  stroke-width: 1.5px;
}
body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-action-dropdown .custom-dropdown-icon .dropdown-menu a:hover {
  background-color: rgba(59, 63, 92, 0.45);
  color: #e0e6ed;
}
body.dark .sidebar-wrapper {
  width: 212px;
  position: fixed;
  z-index: 1030;
  transition: width 0.1s, left 0.1s;
  height: 100vh;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-right: 1px solid #0e1726;
  top: 107px;
}
body.dark .shadow-bottom {
  display: block;
  position: absolute;
  z-index: 2;
  height: 33px;
  width: 101.6%;
  pointer-events: none;
  margin-top: -13px;
  left: -4px;
  -webkit-filter: blur(5px);
  filter: blur(3px);
  background: -webkit-linear-gradient(top, rgba(26, 35, 58, 0.9411764706) 44%, rgba(26, 35, 58, 0.7490196078) 73%, rgba(44, 48, 60, 0));
  background: linear-gradient(180deg, #060818 49%, rgba(6, 8, 24, 0.9215686275) 85%, rgba(44, 48, 60, 0));
}
body.dark .sidebar-theme {
  background: transparent;
}
body.dark .sidebar-closed {
  padding: 0;
}
body.dark .sidebar-closed .sidebar-wrapper {
  width: 0;
  left: -212px;
}
body.dark .sidebar-closed .sidebar-wrapper:hover {
  width: 255px;
}
body.dark .sidebar-closed .sidebar-wrapper:hover span.sidebar-label {
  display: inline-block;
}
body.dark .sidebar-closed .sidebar-wrapper span.sidebar-label {
  display: none;
}
body.dark .sidebar-closed #content {
  margin-left: 0;
}
body.dark #sidebar .theme-brand {
  background-color: transparent;
  padding: 10px 12px 6px 21px;
  border-bottom: 1px solid #fff;
  border-radius: 8px 6px 0 0;
  justify-content: space-between;
  display: none;
}
body.dark .sidebar-closed #sidebar .theme-brand {
  padding: 18px 12px 13px 21px;
}
body.dark .sidebar-closed > .sidebar-wrapper:hover #sidebar .theme-brand {
  padding: 10px 12px 6px 21px;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .nav-logo {
  display: flex;
}
body.dark #sidebar .theme-brand div.theme-logo {
  align-self: center;
}
body.dark #sidebar .theme-brand div.theme-logo img {
  width: 40px;
  height: 40px;
}
body.dark .sidebar-closed .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle {
  display: none;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle {
  align-self: center;
  cursor: pointer;
  overflow: unset !important;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .sidebarCollapse {
  position: relative;
  overflow: unset !important;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .sidebarCollapse:before {
  position: absolute;
  content: "";
  height: 40px;
  width: 40px;
  background: rgba(0, 0, 0, 0.0705882353);
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  left: -8px;
  right: 0;
  z-index: 0;
  opacity: 0;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .sidebarCollapse:hover:before {
  opacity: 1;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .btn-toggle svg {
  width: 25px;
  height: 25px;
  color: #fff;
  transform: rotate(0);
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .btn-toggle svg polyline:nth-child(1) {
  color: #3b3f5c;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .btn-toggle svg polyline:nth-child(2) {
  color: #888ea8;
}
body.dark .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .btn-toggle svg:hover {
  color: #e6f4ff;
}
body.dark .sidebar-closed .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle .btn-toggle svg {
  transform: rotate(-180deg);
}
body.dark .sidebar-closed #sidebar .theme-brand div.theme-text {
  display: none;
}
body.dark .sidebar-closed > .sidebar-wrapper:hover #sidebar .theme-brand li.theme-text a, body.dark .sidebar-closed > .sidebar-wrapper:hover #sidebar .theme-brand div.theme-text, body.dark .sidebar-closed > .sidebar-wrapper:hover #sidebar .theme-brand .sidebar-toggle {
  display: block;
}
body.dark #sidebar .theme-brand div.theme-text a {
  font-size: 25px !important;
  color: #191e3a !important;
  line-height: 2.75rem;
  padding: 0.39rem 0.8rem;
  text-transform: initial;
  position: unset;
  font-weight: 700;
}
body.dark #sidebar .navbar-brand .img-fluid {
  display: inline;
  width: 44px;
  height: auto;
  margin-left: 20px;
  margin-top: 5px;
}
body.dark #sidebar * {
  overflow: hidden;
  white-space: nowrap;
}
body.dark #sidebar ul.menu-categories {
  position: relative;
  padding: 0 0 20px 0;
  margin: auto;
  width: 100%;
  overflow: auto;
}
body.dark #sidebar ul.menu-categories.ps {
  height: calc(100vh - 71px) !important;
  padding-right: 16px;
}
body.dark #sidebar ul.menu-categories li > .dropdown-toggle[aria-expanded=true] svg.feather-chevron-right {
  transform: rotate(90deg);
}
body.dark .sidebar-wrapper ul.menu-categories li.menu.menu-heading {
  height: 56px;
  display: none;
}
body.dark .sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading .feather-minus {
  display: none;
  vertical-align: sub;
  width: 12px;
  height: 12px;
  stroke-width: 4px;
  color: #506690;
}
body.dark .sidebar-closed .sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading .feather-minus {
  display: inline-block;
}
body.dark .sidebar-closed .sidebar-wrapper:hover ul.menu-categories li.menu.menu-heading > .heading .feather-minus {
  display: none;
}
body.dark .sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading {
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  color: #888ea8;
  padding: 32px 0 10px 36px;
  letter-spacing: 1px;
}
body.dark .sidebar-closed > .sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading span {
  display: none;
}
body.dark .sidebar-closed > .sidebar-wrapper:hover ul.menu-categories li.menu.menu-heading > .heading span {
  display: inline-block;
}
body.dark .sidebar-closed #sidebar ul.menu-categories li.menu > .dropdown-toggle {
  padding: 10px 16px;
  transition: 0.6s;
  position: relative;
}
body.dark .sidebar-closed > .sidebar-wrapper:hover #sidebar ul.menu-categories li.menu > .dropdown-toggle {
  transition: 0.6s;
}
body.dark .sidebar-closed .sidebar-wrapper:hover #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true]:before, body.dark .sidebar-closed #sidebar ul.menu-categories li.menu > .dropdown-toggle svg.feather-chevron-right {
  display: none;
}
body.dark .sidebar-closed .sidebar-wrapper:hover #sidebar ul.menu-categories li.menu > .dropdown-toggle svg.feather-chevron-right {
  display: inline-block;
}
body.dark .sidebar-closed .sidebar-wrapper:hover #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true] svg {
  padding: 0;
  background: transparent;
  border-radius: 0;
  border: none;
  width: auto;
  width: 20px;
  height: 20px;
}
body.dark #sidebar ul.menu-categories li.menu:first-child a.dropdown-toggle {
  margin-top: 21px;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  color: #506690;
  padding: 10.2px 16px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 2px;
  border-radius: 8px;
  margin-top: 2px;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle.disabled {
  opacity: 0.5;
  cursor: default;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle.disabled svg:not(.bage-icon) {
  opacity: 0.5;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle.disabled:hover {
  color: #191e3a;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle.disabled:hover svg:not(.bage-icon) {
  color: #515365;
  opacity: 0.5;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle > div {
  align-self: center;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle > div span.sidebar-label {
  position: absolute;
  right: 12px;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle > div span.sidebar-label svg {
  width: 15px;
  height: 15px;
  vertical-align: sub;
}
body.dark #sidebar ul.menu-categories li.menu .dropdown-toggle:after {
  display: none;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle svg:not(.badge-icon) {
  color: #506690;
  margin-right: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  stroke-width: 1.6;
}
body.dark #sidebar ul.menu-categories li.menu.active > .dropdown-toggle {
  background: rgba(96, 125, 139, 0.54);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: #fafafa;
}
body.dark #sidebar ul.menu-categories li.menu.active > .dropdown-toggle[aria-expanded=true] {
  background: rgba(96, 125, 139, 0.54);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: #fafafa;
}
body.dark #sidebar ul.menu-categories li.menu.active > .dropdown-toggle[aria-expanded=true]:hover {
  background: #3b3f5c;
  color: #e0e6ed;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
body.dark #sidebar ul.menu-categories li.menu.active > .dropdown-toggle[aria-expanded=true] svg.feather {
  color: #e0e6ed;
  fill: transparent;
}
body.dark #sidebar ul.menu-categories li.menu.active > .dropdown-toggle svg.feather {
  color: #e0e6ed;
  fill: transparent;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=false] svg.feather-chevron-right {
  transform: rotate(0);
  transition: 0.5s;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true] {
  background: #0e1726;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: #fafafa;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true] svg {
  color: #e0e6ed;
  fill: transparent;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true] svg.feather-chevron-right {
  background-color: transparent;
  transform: rotate(90deg);
  transition: 0.5s;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true] span {
  color: #e0e6ed;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true]:hover {
  color: #e0e6ed;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle[aria-expanded=true]:hover svg {
  color: #e0e6ed !important;
  fill: rgba(67, 97, 238, 0.0392156863);
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle:hover {
  background: #3b3f5c;
  color: #e0e6ed;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle:hover svg:not(.badge-icon) {
  color: #e0e6ed;
}
body.dark #sidebar ul.menu-categories li.menu > .dropdown-toggle svg.feather-chevron-right {
  vertical-align: middle;
  margin-right: 0;
  width: 15px;
}
body.dark #sidebar ul.menu-categories li.menu > a span:not(.badge) {
  vertical-align: middle;
}
body.dark #sidebar ul.menu-categories ul.submenu > li a {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 10px 48px;
  padding-left: 24px;
  margin-left: 36px;
  font-size: 14px;
  color: #888ea8;
}
body.dark #sidebar ul.menu-categories li.menu ul.submenu > li a:before {
  content: "";
  background-color: #506690;
  position: absolute;
  height: 3px;
  width: 3px;
  top: 18.5px;
  left: 13px;
  border-radius: 50%;
}
body.dark #sidebar ul.menu-categories li.menu ul.submenu > li a:hover {
  color: #d78283;
}
body.dark #sidebar ul.menu-categories li.menu ul.submenu > li a:hover:before {
  background: #d78283 !important;
}
body.dark #sidebar ul.menu-categories li.menu ul.submenu > li.active a {
  color: #d78283;
}
body.dark #sidebar ul.menu-categories li.menu ul.submenu > li.active a:before {
  background-color: #506690;
}
body.dark #sidebar ul.menu-categories li.menu ul.submenu > li.active a:hover {
  color: #d78283 !important;
}
body.dark #sidebar ul.menu-categories li.menu ul.submenu > li.active a:hover:before {
  background: #d78283 !important;
}
body.dark #sidebar ul.menu-categories ul.submenu > li {
  margin-top: 3px;
}
body.dark #sidebar ul.menu-categories ul.submenu > li a:hover {
  color: #d78283;
}
body.dark #sidebar ul.menu-categories ul.submenu > li a:hover:before {
  background-color: #d78283;
}
body.dark #sidebar ul.menu-categories ul.submenu > li a i {
  align-self: center;
  font-size: 9px;
}
body.dark #sidebar ul.menu-categories ul.submenu li > [aria-expanded=true] i {
  color: #fff;
}
body.dark #sidebar ul.menu-categories ul.submenu li > [aria-expanded=true]:before {
  background-color: #fff;
}
body.dark #sidebar ul.menu-categories ul.submenu li > a[aria-expanded=true] {
  color: #d78283;
}
body.dark #sidebar ul.menu-categories ul.submenu li > a[aria-expanded=true]:before {
  background-color: #d78283 !important;
}
body.dark #sidebar ul.menu-categories ul.submenu > li a.dropdown-toggle {
  padding: 10px 32px 10px 33px;
  padding: 10px 12px 10px 48px;
  padding-left: 24px;
  margin-left: 36px;
}
body.dark #sidebar ul.menu-categories ul.submenu > li a.dropdown-toggle svg {
  align-self: center;
  transition: 0.3s;
  width: 13px;
  height: 13px;
}
body.dark #sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a {
  position: relative;
  padding: 10px 12px 10px 48px;
  padding-left: 15px;
  margin-left: 56px;
  font-size: 14px;
  color: #888ea8 !important;
  letter-spacing: 1px;
}
body.dark #sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li.active a {
  color: #d78283 !important;
}
body.dark #sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:hover {
  color: #d78283;
}
body.dark #sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:hover:before {
  background-color: #d78283;
  box-shadow: none;
}
body.dark #sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:before {
  content: "";
  background-color: #bfc9d4;
  position: absolute;
  top: 19.5px !important;
  border-radius: 50%;
  left: 3px;
  height: 3px;
  width: 3px;
}
body.dark #sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li.active a:before {
  background-color: #009688;
}
body.dark .overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1035 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  touch-action: pan-y;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body.dark .e-animated {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
body.dark .e-fadeInUp {
  -webkit-animation-name: e-fadeInUp;
  animation-name: e-fadeInUp;
}
body.dark .footer-wrapper {
  padding: 10px 0 10px 0;
  display: inline-block;
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  border-top-left-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 10px 24px 10px 24px;
  margin: auto;
  margin-top: 15px;
}
body.dark .layout-boxed .footer-wrapper {
  max-width: 1488px;
}
body.dark .main-container.sidebar-closed .footer-wrapper {
  border-radius: 0;
}
body.dark .footer-wrapper .footer-section p {
  margin-bottom: 0;
  color: #888ea8;
  font-size: 14px;
  letter-spacing: 1px;
}
body.dark .footer-wrapper .footer-section p a {
  color: #888ea8;
}
body.dark .footer-wrapper .footer-section svg {
  color: #e7515a;
  fill: #e7515a;
  width: 15px;
  height: 15px;
  vertical-align: sub;
}
body.dark.alt-menu .header-container {
  transition: none;
}
body.dark.alt-menu #content {
  transition: none;
}

/*  
    ======================
        MEDIA QUERIES
    ======================
*/
@media (max-width: 991px) {
  body.dark {
    /*
        =============
            NavBar
        =============
    */
    /*
        =============
            Sidebar
        =============
    */
    /* display .overlay when it has the .active class */
  }
  body.dark .header-container.container-xxl {
    left: 0;
  }
  body.dark .header-container .theme-text {
    margin-right: 0;
  }
  body.dark .layout-px-spacing {
    padding: 0 16px !important;
  }
  body.dark .main-container.sidebar-closed #content {
    margin-left: 0;
  }
  body.dark .navbar .search-animated {
    margin-left: auto;
  }
  body.dark .navbar .search-animated svg {
    margin-right: 0;
    display: block;
  }
  body.dark .search-active .form-inline.search {
    display: flex;
  }
  body.dark #content {
    margin-left: 0;
  }
  body.dark #sidebar .theme-brand {
    border-radius: 0;
    padding: 14px 12px 13px 21px;
  }
  body.dark .sidebar-closed #sidebar .theme-brand {
    padding: 14px 12px 13px 21px;
  }
  body.dark .sidebar-closed #sidebar .theme-brand div.theme-text {
    display: block;
  }
  body.dark .sidebar-closed .sidebar-wrapper.sidebar-theme .theme-brand .sidebar-toggle {
    display: block;
  }
  body.dark .main-container:not(.sbar-open) .sidebar-wrapper {
    width: 0;
    left: -52px;
  }
  body.dark body.alt-menu .sidebar-closed > .sidebar-wrapper {
    width: 255px;
    left: -255px;
  }
  body.dark .main-container {
    padding: 0;
  }
  body.dark #sidebar ul.menu-categories.ps {
    height: calc(100vh - 1px) !important;
    padding-left: 16px;
  }
  body.dark .sidebar-wrapper {
    top: 0;
    bottom: 0;
    z-index: 9999;
    border-radius: 0;
    left: 0;
    width: 255px;
    background: #060818;
  }
  body.dark .sidebar-noneoverflow {
    overflow: hidden;
  }
  body.dark #sidebar {
    height: 100vh !important;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
  body.dark .overlay.show {
    display: block;
    opacity: 0.7;
  }
}
@media (min-width: 992px) {
  body.dark .sidebar-noneoverflow .header-container.container-xxl {
    left: 84px;
  }
  body.dark .sidebar-closed #sidebar .theme-brand li.theme-text a {
    display: none;
  }
}
@media (max-width: 767px) {
  body.dark .header-container {
    padding: 7.5px 20px 7.5px 16px;
  }
  body.dark .header-container .navbar.navbar-expand-sm .navbar-item.theme-brand {
    padding-left: 0;
  }
  body.dark .header-container .navbar.navbar-expand-sm .navbar-item .nav-item.theme-text {
    display: none;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated {
    position: relative;
    display: flex;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated svg.feather-search {
    font-weight: 600;
    margin: 0 9.6px;
    margin: 0;
    cursor: pointer;
    color: #e0e6ed;
    position: initial;
    width: 24px;
    height: 24px;
    transition: top 200ms;
    top: -25px;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated form.form-inline input {
    display: none;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated .badge {
    display: none;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated.show-search form {
    position: fixed;
    top: 0;
    background: #060818;
    height: 55px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 32;
    margin-top: 0px !important;
    display: flex;
    opacity: 1;
    transition: opacity 200ms, top 200ms;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated.show-search form.form-inline .search-bar {
    width: 100%;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated.show-search form.form-inline .search-bar input {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-left: 24px;
  }
  body.dark .header-container .navbar.navbar-expand-sm .search-animated.show-search form.form-inline .search-bar .search-close {
    display: block;
    right: 10px;
    top: 17px;
  }
  body.dark .header-container .navbar.navbar-expand-sm .action-area {
    padding: 0;
  }
  body.dark .secondary-nav .breadcrumbs-container .navbar .sidebarCollapse {
    padding: 0 13px 0 24px;
  }
  body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-content .page-header nav .breadcrumb .breadcrumb-item:not(.active) {
    display: none;
  }
  body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-content .page-header nav .breadcrumb .breadcrumb-item.active {
    padding-left: 0;
    vertical-align: sub;
    font-size: 15px;
    font-weight: 600;
  }
  body.dark .secondary-nav .breadcrumbs-container .navbar .breadcrumb-content .page-header nav .breadcrumb .breadcrumb-item.active:before {
    display: none;
  }
}
@media (max-width: 575px) {
  body.dark .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
    right: auto;
    left: -76px !important;
  }
  body.dark .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
    right: -64px !important;
  }
  body.dark .navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu {
    right: auto !important;
    left: -56px !important;
  }
  body.dark .footer-wrapper .footer-section.f-section-2 {
    display: none;
  }
}
.main_profile_img img {
	width: 120px;
	height: 120px;
	object-fit: cover;
  border-radius: 15px;
}
.gamer-name {
	line-height: 1;
}
.user-nam-details {
	margin-left: 20px;
}
.label-view-text {
	color: #fff;
	font-size: 16px;
}
.org-text {
	color: #F66062 !important;
}
.video-part img {
	width: 100%;
}
.gamer-name label {
	font-size: 13px !important;
}