/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body.dark:before {
  display: none;
}

body.dark .auth-container {
  min-height: 100vh;
}
body.dark .auth-container .container {
  max-width: 1440px;
}
body.dark .auth-container .card {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
body.dark .auth-container .card .card-body {
  padding-top: 0;
  padding-bottom: 0;
}
body.dark .seperator {
  position: relative;
}
body.dark .seperator .seperator-text {
  position: absolute;
  top: -10px;
  display: block;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}
body.dark .seperator .seperator-text span {
  background-color: #060818;
  padding: 0 12px;
  display: inline-block;
}
body.dark .auth-cover-bg-image {
  position: absolute;
  width: 55%;
  top: 0;
  bottom: 0;
  left: 0;
}
body.dark .auth-cover img {
  width: 450px;
  height: 450px;
}
body.dark .auth-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: inherit;
  background-image: linear-gradient(-225deg, #242A38 0%, #242A38 29%, #242A38 100%);
  width: 55%;
}
body.dark .opt-input {
  padding: 12px 14px;
  text-align: center;
}
body.dark .btn-social-login img {
  width: 25px;
  height: 25px;
}

@media (max-width: 1599px) and (min-width: 1400px) {
  /* body.dark .ms-lg-auto {
    margin-left: auto !important;
    padding: 0;
  } */
  body.dark .auth-cover-bg-image {
    width: 50%;
  }
  body.dark .auth-overlay {
    width: 50%;
  }
}
@media (max-width: 575px) {
  body.dark .auth-container {
    height: auto;
  }
  body.dark .auth-container .card .card-body {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}



.box_p_30{
  padding: 20px !important;
}